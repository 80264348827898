import React from 'react';
import LocationPageTemplate from '../../components/Location/LocationPageTemplate';

function UsaOffice() {
  return (
    <LocationPageTemplate
      mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3234.388788679319!2d-78.72224138449465!3d35.8394691288843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89acf6eb4d5abf7f%3A0xf8877ef1c95703d6!2s5028%20Neiman%20Cove%2C%20Raleigh%2C%20NC%2027612%2C%20USA!5e0!3m2!1sen!2s!4v1620317250305!5m2!1sen!2s"
      analyticsPageviewTitle="Location/Usa"
      city="USA"
      locationImgSrc={require('../../assets/images/location/usa.png').default}
      addresses={[
        {
          title: 'Tintash Inc',
          description: (
            <>
              5028 Neiman Cove, Raleigh, NC 27612
              <br />
              Phone: +1 (650) 204-9718
            </>
          ),
        },
      ]}
    />
  );
}

export default UsaOffice;
